.loading-text {
  text-align: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 0);
}

a,
a:visited {
  color: cyan;
}
